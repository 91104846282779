<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-tab
      type="tabcard"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :searchParam.sync="searchParam"
          :tabParam.sync="tabParam"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'emergency-relation-info',
  data() {
    return {
      searchParam: {
        plantCd: '',
        dataTypeCd: '1',
        useFlag: 'Y',
        innerFlag: 'Y',
      },
      editable: true,
      useFlagItems: [],
      tab: '1',
      tabItems: [
        { name: '1', icon: 'folder', label: '비상연락체계도', component: () => import(`${'./emergencyRelationInfoDetail.vue'}`) },
        { name: '2', icon: 'folder', label: '자위소방대조직도', component: () => import(`${'./emergencyRelationInfoDetail.vue'}`) },
        { name: '3', icon: 'folder', label: '반별임무', component: () => import(`${'./emergencyRelationInfoDetail.vue'}`) },
        { name: '4', icon: 'folder', label: '인근업체비상연락망', component: () => import(`${'./emergencyRelationInfoDetail.vue'}`) },
        { name: '5', icon: 'folder', label: '집결지', component: () => import(`${'./emergencyRelationInfoDetail.vue'}`) },
        { name: '6', icon: 'folder', label: '비상대피로', component: () => import(`${'./emergencyRelationInfoDetail.vue'}`) },
        { name: '7', icon: 'phone_in_talk', label: '응급상황 대응', component: () => import(`${'@/pages/sop/eme/EmeContactManage.vue'}`) },
      ],
      tabParam: {
        dataTypeCd: '1',
        title: '비상연락체계도', 
      },
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      // url setting
      // code setting
      // list setting
    },
    tabClick(tab) {
      this.searchParam.dataTypeCd = tab.name;
      this.tabParam.dataTypeCd = tab.name;
      this.tabParam.title = tab.label;
    }
  }
};
</script>
